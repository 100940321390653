<template>
  <div>
    <v-toolbar color="blue-grey darken-3" dense dark>
      <v-toolbar-title>Auto BOT V-0.1</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text>
        Logoff
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
export default {
    name: "Toollbar",
//   mixins: [globalFunctionMixin],

  components: {
    // GenaralTable,
  },

  computed: {
    // lines_data() {
    //   //   console.log("update lines_data");
    //   return this.$store.getters["page/LineList"];
    // },
  },

  methods: {
    // back_to_datamenu() {
    //   this.$router.push("/Menu_Data");
    // },
  },

  data: () => ({}),

            //       //save token
            //       localStorage.setItem("blog_token", data.response.token);
            //   //save id
            //   sessionStorage.setItem("UserID", data.response.user.Username);
            //   //save nickname
            //   sessionStorage.setItem("Nickname", data.response.user.Nickname);
};
</script>

<style></style>
