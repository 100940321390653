<template>
  <v-card min-width="100%" min-height="200" :color="Color + ' darken-4'" dark>
    <v-card-title primary class="title"
      >{{ WebName }} : {{ Roomname }}
      <v-spacer></v-spacer>

      <v-btn
        class="mx-2"
        fab
        dark
        small
        :color="Color + ' darken-2'"
        @click="manage_click()"
      >
        <v-icon dark>
          mdi-tune
        </v-icon>
      </v-btn>
      <!-- <v-switch
                v-model="switch1"
                label="ON"
                color="pink accent-2"
                hide-details
              ></v-switch> -->
    </v-card-title>

    <v-divider></v-divider>
    <!-- <v-card-text > -->
    <v-list color="rgb(0, 0, 0, 0)">
      <!-- โปร่งใส -->
      <v-subheader class="white--text"
        >งวด <v-icon class="ml-3 mr-3">mdi-calendar-month-outline</v-icon>
        {{ RoundDate }} <v-icon class="ml-3 mr-3">mdi-clock-outline</v-icon>
        {{ Round }}</v-subheader
      >
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="white">
            mdi-play-speed
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>สถานะ</v-list-item-title>
        </v-list-item-content>
        <!-- v-text="" -->
        <v-list-item-content>
          <v-list-item-title class="text-right">{{
            getStatus()
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="white">
            mdi-laptop
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>เครื่องที่เปิด (เครื่อง)</v-list-item-title>
        </v-list-item-content>
        <!-- v-text="" -->
        <v-list-item-content>
          <v-list-item-title class="text-right">{{MACHINE_ONLINE_COUNT}}/{{MACHINE_COUNT}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="white">
            mdi-chart-timeline-variant-shimmer
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>แทงแล้ว/ทั้งหมด (รอบ)</v-list-item-title>
        </v-list-item-content>
        <!-- v-text="" -->
        <v-list-item-content>
          <v-list-item-title class="text-right">6/12</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="white">
            mdi-chart-tree
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>ได้โบนัส/ทั้งหมด (id)</v-list-item-title>
        </v-list-item-content>
        <!-- v-text="" -->
        <v-list-item-content>
          <v-list-item-title class="text-right">6/32</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="white">
            mdi-currency-usd
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>เงินหมด/ใกล้หมด/ปกติ</v-list-item-title>
        </v-list-item-content>
        <!-- v-text="" -->
        <v-list-item-content>
          <v-list-item-title class="text-right">1/1/24</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- </v-card-text> -->
  </v-card>
</template>

<script>
export default {
  name: "PanelOverview",

  props: [
    "WebName",
    "Roomname",
    "Color",
    "Round",
    "RoundDate",
    "BetType",
    "Status",
    "MACHINE_COUNT",
    "MACHINE_ONLINE_COUNT",
  ],
  created() {
    //โหลด ตาราง
    // this.fetch_table();
  },

  methods: {
    manage_click() {
      //setting link
      sessionStorage.setItem("WebName", this.WebName);
      sessionStorage.setItem("Roomname", this.Roomname);
      
      this.$router.push("/Manage");
    },
    getStatus() {
      // 0=new,1=betting,2=end,3=won,4=lowid,5=lowworker
      // 0=Time,1=Loop,2=Loop and last,3=Loop random begin and last,4=ai time
      // 0=Stop,1=Play,2=Pause,3=TestAlone,4=TestGroup
      // console.log(this.Status);
      switch (this.Status) {
        case "0":
          return "หยุด";
        case "1":
          return "ใช้งาน";
        default:
          return "";
      }
    },
    // getStatus(){
    //     // 0=new,1=betting,2=end,3=won,4=lowid,5=lowworker
    //     // console.log(this.Status);
    //     switch (this.Status) {
    //         case -1:
    //             return "ไม่ได้แทง"
    //         case 0:
    //             return "รอเวลาเริ่มงวด"
    //         case 1:
    //             return "กำลังแทง"
    //         case 2:
    //             return "จบงวด"
    //         case 3:
    //             return "ได้โบนัส"
    //         case 4:
    //             return "id ไม่พอแทง"
    //         case 5:
    //             return "เครื่องไม่พอแทง"
    //         default:
    //             break;
    //     }
    // },
    // async fetch_table() {
    //   let credentials = {
    //     WebName: sessionStorage.getItem("period"),
    //     // ListType: "hp_by_number",
    //     // BetType: this.BetType,
    //   };
    //   await this.$store.dispatch("web/get_overview_list", credentials).then(
    //     (response) => {
    //       //console.log(response);
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
    // },
  },
  data: () => ({}),
};
</script>

<style></style>
