<template>
  <v-container fluid grid-list-md>
    <Toolsbar />
    <v-layout row wrap>
      <v-flex
        d-flex
        xs12
        sm3
        md4
        lg4
        xl3
        v-for="item in this.$store.getters['web/RoomList']"
        :key="item.id"
      >
        <OverviewPanel
          :WebName="item.WebName"
          :Roomname="item.Roomname"
          :Round="item.Round"
          :RoundDate="item.RoundDate"
          :BetType="item.BetType"
          :Status="item.Status"
          :MACHINE_COUNT="item.MACHINE_COUNT"
          :MACHINE_ONLINE_COUNT="item.MACHINE_ONLINE_COUNT"
          :Color="item.Color"
        />
      </v-flex>

      <!-- <v-flex md4 v-for="item in this.$store.getters.getItems":key="item.id"></v-flex> -->
      <!-- room_list -->
      <!-- <v-flex d-flex xs12 sm3 md4 lg4 xl3>
        <v-card min-width="100%" min-height="200" color="cyan darken-4" dark>
          <v-card-title primary class="title">JEDSADABET</v-card-title>
          <v-card-text>きんじつはつばい</v-card-text>
        </v-card>
      </v-flex>

      <v-flex d-flex xs12 sm3 md4 lg4 xl3>
        <v-card min-width="100%" min-height="200" color="blue-grey" dark>
          <v-card-title primary class="title">LOTTOVIP</v-card-title>
          <v-card-text>COMING SOON</v-card-text>
        </v-card>
      </v-flex> -->
    </v-layout>
  </v-container>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import Toolsbar from "@/components/Layouts/Toolsbar";
// import TODE120Panel from "@/components/M15MIN/Panel";
import OverviewPanel from "@/components/Panel/Overview";

export default {
  name: "Dashboard",
  mixins: [globalFunctionMixin],

  components: {
    Toolsbar,
    OverviewPanel,
  },
  mounted() {
    this.ckLogin();
    // this.$refs.b_nav.SetID(0);
    this.fetch_table();
    window.scrollTo(0, 0);
    
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    // room_list() {
    //   return this.$store.getters["web/RoomList"];
    // },
  },
  //   RoomList
  methods: {
    async fetch_table() {
      let credentials = {
        // WebName: sessionStorage.getItem("period"),
        // ListType: "hp_by_number",
        // BetType: this.BetType,
      };
      await this.$store.dispatch("web/get_overview_list", credentials).then(
        (response) => {
          //   console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },

  data: () => ({
    switch1: true,
  }),
};
</script>

<style></style>
